<template>
  <PageComponent>
    <template #pageBodySlot>
      <div class="ow-home-page" :class="{ 'dropdown-active': dropDownActive }">
        <div class="ow-home-page-information-panel">
          <video class="ow-home-page-information-panel-video" autoplay muted loop>
            <source src="/images/OxiaHomeVideo.mp4" type="video/mp4">
          </video>
          <img src="/images/HomePageCover.png" class="ow-home-page-information-panel-image" />
          <div class="ow-home-page-information-panel-text">
            <div class="ow-home-page-information-panel-header">
              The Future of Preventative Care 
              <div class="ow-home-page-information-panel-header-image-container">
                <img src="/images/OxiaNavbarLogo.png" alt="home page oxia text img" class="ow-home-page-information-panel-header-image">
              </div>
              <span class="ow-home-page-information-panel-sub-header">Screen Smart - Live Smart</span>
            </div>
          </div>
        </div>
        <div class="ow-home-page-cards-wrapper">
          <div class="ow-home-page-cards-container">
            <div class="ow-home-page-card">
              <div class="ow-home-page-card-header">
                <h3 class="ow-home-page-card-header-text ow-heading-3">If you are a</h3>
                <h2 class="ow-home-page-card-header-title ow-heading-2">PATIENT</h2>
                <!-- <span class="ow-home-page-card-header-text">If you are a</span>
                <span class="ow-home-page-card-header-title">PATIENT</span> -->
              </div>
              <div class="ow-home-page-card-button-container patient-container">
                <!-- <button class="oxia  -->
                <button class="ow-button button-default" @click="handleButtonClicked('patient')">MORE
                  INFO</button>
              </div>
            </div>
            <div class="ow-home-page-card">
              <div class="ow-home-page-card-header">
                <h3 class="ow-home-page-card-header-text ow-heading-3">If you are a</h3>
                  <h2 class="ow-home-page-card-header-title ow-heading-2">PROFESSIONAL</h2>
              </div>
              <div class="ow-home-page-card-button-container">
                <div class="ow-home-page-card-dropdown" :class="{ 'is-expanded': dropDownActive }">
                  <input type="checkbox" name="" id="professional-checkbox" v-model="dropDownActive">
                  <label class="ow-home-page-card-dropdown-label ow-button button-default text-wrap"
                    for="professional-checkbox">CHOOSE YOUR PROFESSION</label>
                  <div class="os-icon icon-down" @click="toggleDropdown"></div>
                  <div class="ow-home-page-card-dropdown-list-wrapper">
                    <ul class="ow-home-page-card-dropdown-list os-scrollbar" ref="dropDownListRef">
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('ophthalmologist')"
                          class="ow-home-page-card-dropdown-list-item-button">
                          Ophthalmologist
                        </button>
                      </li>
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('physician')" class="ow-home-page-card-dropdown-list-item-button">
                          Physician
                        </button>
                      </li>
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('GP')" class="ow-home-page-card-dropdown-list-item-button">
                          General Practitioner
                        </button>
                      </li>
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('optometrist')"
                          class="ow-home-page-card-dropdown-list-item-button">
                          Optometrist
                        </button>
                      </li>
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('pharmacy')" class="ow-home-page-card-dropdown-list-item-button">
                          Pharmacy
                        </button>
                      </li>
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('independent-sc')"
                          class="ow-home-page-card-dropdown-list-item-button">
                          Independent Screening Centre
                        </button>
                      </li>
                      <li class="ow-home-page-card-dropdown-list-item">
                        <button @click="handleButtonClicked('other')" class="ow-home-page-card-dropdown-list-item-button">
                          Other
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="ow-home-page-card">
              <div class="ow-home-page-card-header">
                <h3 class="ow-home-page-card-header-text ow-heading-3">If you are interested in</h3>
                <h2 class="ow-home-page-card-header-title ow-heading-2">EMPLOYEE WELLNESS</h2>
              </div>
              <div class="ow-home-page-card-button-container">
                <button class="ow-button button-default" @click="handleButtonClicked('employee-wellness')">MORE
                  INFO</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageComponent>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const dropDownActive = ref(false);
const dropDownListRef = ref(null);

useSeoMeta({
  title: 'OxIA Website',
  ogTitle: 'OxIA Website',
  description: 'OxIA is a company...',
  ogDescription: 'OxIA is a company...',
  ogImage: 'https://oxia.org/images/HomePageCover.png',
  twitterCard: 'summary_large_image',
})

watch(() => dropDownActive.value, (newActive) => {
  if (newActive || (newActive && newScroll)) {
    const [pageContent] = document.getElementsByClassName("page-content");
    let topOfDropDown = dropDownListRef.value.getBoundingClientRect().top + window.scrollY;
    let endingPosition = document.documentElement.scrollHeight - 70;
    let maxHeight = endingPosition - topOfDropDown;

    if (dropDownListRef.value.scrollHeight > 1 && maxHeight > 0) dropDownListRef.value.style.maxHeight = `${maxHeight}px`;
    else dropDownListRef.value.style.maxHeight = '';

    // Scroll to bottom after 0.1s so the dropdown is visible
    setTimeout(() => {
      pageContent.scrollTo({
        left: 0,
        top: pageContent.scrollHeight,
        behavior: "smooth"
      });
    }, 200);
  }
});

const toggleDropdown = () => {
  dropDownActive.value = !dropDownActive.value;
}

const handleButtonClicked = (clickEvent) => {
  switch (clickEvent) {
    case "patient":
      router.push("patient");
      break;
    case "optometrist":
      router.push("optometrist");
      break;
    case "employee-wellness":
      router.push("employee-wellness");
      break;
    case "ophthalmologist":
      router.push("ophthalmologist");
      break;
    case "GP":
      router.push("general-practitioner");
      break;
    case "physician":
      router.push("physician");
      break;
    case "pharmacy":
      router.push("pharmacy");
      break;
    case "independent-sc":
      router.push("independent-screening-centre");
      break;
    case "book-patient":
      router.push("book-your-appointment");
      break;
    case "other":
      router.push("other");
      break;
    default:
      break;
  }
};
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.5s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.8rem);
}
</style>